.task-name-edit {
    padding: 0px !important;
    margin: 0px !important;
    outline: none;
    border-color: transparent;
    width: 100%;
    overflow: hidden;
    height: 24px;
}

.highlight-row-project-recive-requirement {
    background-color: #eed2ff !important;
    /* สีพื้นหลังที่ต้องการ */
}

.highlight-row-project-recive-requirement > td {
    background-color: #eed2ff !important;
    /* สีพื้นหลังที่ต้องการ */
}

.highlight-row-project-design {
    background-color: #fff9d2 !important;
    /* สีพื้นหลังที่ต้องการ */
}

.highlight-row-project-design > td {
    background-color: #fff9d2 !important;
    /* สีพื้นหลังที่ต้องการ */
}

.highlight-row-project-coding {
    background-color: #d2f3ff !important;
    /* สีพื้นหลังที่ต้องการ */
}

.highlight-row-project-coding > td {
    background-color: #d2f3ff !important;
    /* สีพื้นหลังที่ต้องการ */
}

.highlight-row-project-test {
    background-color: #ffddd2 !important;
    /* สีพื้นหลังที่ต้องการ */
}

.highlight-row-project-test > td {
    background-color: #ffddd2 !important;
    /* สีพื้นหลังที่ต้องการ */
}

.highlight-row-project-done {
    background-color: #d4ffd2 !important;
    /* สีพื้นหลังที่ต้องการ */
}

.highlight-row-project-done > td {
    background-color: #d4ffd2 !important;
    /* สีพื้นหลังที่ต้องการ */
}
