.highlight-row-customer-open-case-non-owner {
    background-color: #fff9d2 !important;
    /* สีพื้นหลังที่ต้องการ */
}

.highlight-row-customer-open-case-non-owner > td {
    background-color: #fff9d2 !important;
    /* สีพื้นหลังที่ต้องการ */
}

.case-description p {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}