@import './fonts.css';

label {
  font-family: IBMPlexSansThai-Regular;
}

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background-color: #eeeeee;
}

::-webkit-scrollbar-thumb {
  background: rgb(136, 136, 136);
  /* box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); */
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(85, 85, 85);
}

.partition {
  box-sizing:border-box;
}

article{ 
  min-height: 100vh; 
  margin: 0; 

  display: grid;
  grid-template-rows: auto 1fr auto;
}

header{ 
  min-height:50px;
}

footer{ 
  min-height:50px; 
}

.wrapper {
  height: 100vh;
  display: flex;

  flex-direction: column;
}

main {
  flex: 1;
}

.ant-table-cell {
  padding: 2px !important;
}

th {
  height: 40px !important;
}

.otpContainer {
  margin: 5% auto;
}

.otpInput {
  width: 3rem !important;
  height: 3rem;
  margin: 0 0.3rem;
  font-size: 24px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid rgba(131, 131, 131, 0.3);
}

.otpInput:focus {
  border: 2px solid rgba(131, 131, 131, 0.3);
  box-shadow: 0 0 3px #1670BE;
  outline-offset: 0px;
  outline: none;
}

/*  Add breakpoint for iPhone */
@media only screen and (max-width: 375px) {
  .otpInput {
    width: 1.5rem !important;
    height: 1.5rem;
    font-size: 1rem;
    padding: 8px;
  }
}

.site-back-top-basic {
  color: rgba(64, 64, 64, 0.6);
}
