
.doc-name-edit {
    padding: 0px !important;
    margin: 0px !important;
    outline: none;
    border-color: transparent;
    width: 100%;
    overflow: hidden;
    height: 24px;
}

.dot-line {
    position: relative;
    width: 100%;
    margin-bottom: 4px; /* ระยะห่างระหว่างบรรทัด */
}

.dot-line span {
    position: relative;
    z-index: 1; /* ให้อยู่เหนือจุดไข่ปลา */
    background-color: #fff; /* สีพื้นหลังของข้อความ */
}

.dot-line::before {
    content: "";
    position: absolute;
    top: 65%;
    left: 0;
    right: 0;
    height: 1px;
    background: repeating-linear-gradient(
        to right,
        #000,
        #000 2px,
        transparent 2px,
        transparent 4px
    ); /* จุดไข่ปลา */
    transform: translateY(-50%);
    z-index: 0; /* ให้อยู่ใต้ข้อความ */
}
